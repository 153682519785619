import Vue from 'vue';

// hvis du tilføjer properties, så husk at cleare nye properties i logout funktionen på profilsiden.
export const INIT_STATE = {
  profile: null,
  multiprofiles: null,
  target: null,
  medlem: null,
  ledergrupper: null,
  medlemgrupper: null,
  conventusadmin: false
};

export const observableState = Vue.observable({
  state: JSON.parse(JSON.stringify(INIT_STATE))
});
