<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          class=""
          :style="{
            'background-color': $vuetify.theme.dark
              ? 'rgba(0,0,0,0.97)'
              : 'rgba(255,255,255,0.99)'
          }"
        >
          <v-card-title>
            <Avatar
              :fullname="$appstate.profile.navn"
              :radius="20"
              class="mr-4"
              :size="40"
            />
            {{ $appstate.profile?.navn }}
            <v-card-subtitle>{{ $appstate.profile.email }}</v-card-subtitle>
            <v-spacer />
            <v-btn text @click="logout">Logout</v-btn>
          </v-card-title>
        </v-card>
        <div v-if="$appstate.ledergrupper">
          <v-row v-for="gruppe in $appstate.ledergrupper" :key="gruppe">
            <v-col>
              <LederHold :gruppeid="gruppe" />
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col>
            <v-card
              v-if="$appstate.conventusadmin"
              :style="{
                'background-color': $vuetify.theme.dark
                  ? 'rgba(0,0,0,0.97)'
                  : 'rgba(255,255,255,0.99)'
              }"
            >
              <v-card-title>Conventus noter</v-card-title>
              <v-card-subtitle
                >Dette er Sole's egen bunke af hjælpe noter omkring Conventus.
                <br />De er kun synlige for dig, fordi du er med i gruppen
                conventus "Hovedbestyrelsen", "Kampfordeler", "Kasserer" eller
                "Webmastere".</v-card-subtitle
              >
              <div class="pa-4">
                Trænere og hjælpetrænere skal oprettes eller tilføjes ved at
                redigere holdet og tilføje her.
                <img
                  src="conventus/conventus-2022-11-05-071337.png"
                  class="elevation-2 pa-4 ma-4"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import conventusapi from '@/mixins/conventusapi';
import Avatar from 'vue-avatar-component';
import conventusapi from '@/mixins/conventusapi';
import LederHold from '@/components/LederHold.vue';

export default {
  components: { Avatar, LederHold },
  mixins: [conventusapi],
  async mounted() {},
  computed: {},
  methods: {
    logout() {
      this.$appstate.profile = null;
      this.$appstate.multimedlemmer = null;
      this.$appstate.medlem = null;
      this.$appstate.ledergrupper = null;
      this.$appstate.medlemgrupper = null;
      this.$appstate.conventusadmin = false;
      this.$router.push('/');
    }
  }
};
</script>
