<template>
  <div v-if="visible">
    <v-card
      :style="{
        'background-color': $vuetify.theme.dark
          ? 'rgba(0,0,0,0.6)'
          : 'rgba(255,255,255,0.95)'
      }"
    >
      <v-card-title>
        Leder: {{ gruppe.gruppe.titel }}
        <v-spacer />
        Abonnement kr.: {{ gruppe.gruppe.type }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="medlemmer"
          :headers="headers"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import conventusapi from '@/mixins/conventusapi';
import conventusapi from '@/mixins/conventusapi';

export default {
  props: {
    gruppeid: {
      type: String,
      required: false
    }
  },
  mixins: [conventusapi],
  data() {
    return {
      medlemmer: null,
      gruppe: null,
      visible: true,
      headers: [
        {
          text: 'Navn',
          align: 'left',
          sortable: false,
          value: 'navn'
        },
        { text: 'Mobil', value: 'mobil' },
        { text: 'Email', value: 'email' },
        { text: 'Adresse', value: 'adresse1' },
        { text: 'By', value: 'postnr_by' }
      ]
    };
  },
  async mounted() {
    const response = await this.fetchMedlemmer(this.gruppeid);
    this.medlemmer = response.medlemmer.medlem;
    this.gruppe = await this.fetchGruppe(this.gruppeid);
    if (this.gruppe.gruppe.type === 'udvalg') {
      this.visible = false;
    }
  },
  computed: {},
  methods: {
    ended() {}
  }
};
</script>
