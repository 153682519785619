import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/mainPages/HomePage';
import TestPage from '@/views/mainPages/TestPage';
import TeamPage from '@/views/mainPages/TeamPage';
import TeamPageJSVersion from '@/views/mainPages/TeamPageJSVersion';
import ClubInformation from '@/views/mainPages/ClubInformation';
import Fodbold from '@/views/afdelingsPages/FodboldPage';
import Esport from '@/views/afdelingsPages/EsportPage';
import Badminton from '@/views/afdelingsPages/BadmintonPage';
import ProfilePage from '@/views/secure/ProfilePage';
import Login from '@/views/mainPages/LoginPage';
import NotFound from '../views/mainPages/NotFound';

import { observableState } from '@/components/applicationstate';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/testpage',
    component: TestPage
  },
  {
    path: '/holdoversigt',
    component: TeamPage
  },
  {
    path: '/holdoversigtjs',
    component: TeamPageJSVersion
  },
  {
    path: '/klubinfo',
    component: ClubInformation
  },

  {
    path: '/badminton',
    component: Badminton
  },
  {
    path: '/fodbold',
    component: Fodbold
  },
  {
    path: '/esport',
    component: Esport
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/profil',
    component: ProfilePage,
    meta: {
      secure: true
    }
  },
  {
    path: '*',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (!observableState.state.profile && to.meta?.secure) {
    // secure:true skal sættes på routens meta data.
    observableState.state.target = to; // vi sender folk over på login siden, men vi skal lagre den side de gerne vil ind på bagefter.
    next({
      path: '/login'
      // Redirect til vores login side.
    });
  } else {
    next(); // ellers ingen problem, bare vis siden.
  }
});
export default router;
