<template>
  <v-footer dark v-if="!$vuetify.breakpoint.mobile">
    <v-row>
      <v-col>
        <v-row class="mt-12" justify="center">
          <v-col cols="10" lg="3" md="3" sm="4">
            <p style="margin: 0">Information</p>
            <v-list class="transparent">
              <v-list-item
                class="listitem"
                v-for="information in informations"
                :key="information.name"
              >
                <span>{{ information.name }}</span>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="10" lg="3" md="3" sm="4">
            <p>Følg os</p>
            <v-btn
              large
              class="mr-4"
              icon
              outlined
              v-for="icon in socials"
              :key="icon.name"
              :href="icon.href"
              target="_blank"
            >
              <v-icon>{{ icon.icon }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10" lg="3" md="3" sm="8">
            <p>Sole Idrætsforening</p>

            CVR nr: 71996913<br />
            Adresse: Ribevej 67<br /><br />
            Kontakt person til hjemmeside:<br /><a
              href="mailto:mobil@smilnet.dk"
              >Ulrik Stig Vesterdal Holm</a
            ><br />
          </v-col>
        </v-row>
        <v-row class="mt-6 mb-6 bottomtext text-center" justify="center">
          <v-col align-self="end" cols="10" lg="3" sm="8">
            <span>Privatlivspolitik</span>
            <span class="mx-2">Cookiepoltik</span>
            <span>CVR</span>
          </v-col>
          <v-col cols="10" lg="3" sm="6">
            <v-img
              height="100px"
              contain
              src="../../assets/nylogoSVG-white.svg"
            ></v-img
          ></v-col>
          <v-col align-self="end" cols="10" lg="3" sm="8">
            Copyright 2022 SoleIF</v-col
          ></v-row
        >
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      textfields: [
        { label: 'Fornavn' },
        { label: 'Efternavn' },
        { label: 'Email' }
      ],
      socials: [
        {
          name: 'facebook',
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/soleif1941/'
        },
        { name: 'Youtube', icon: 'mdi-youtube' },
        { name: 'Instagram', icon: 'mdi-instagram' }
      ],
      informations: [
        { name: 'Nyheder' },
        { name: 'Kampprogram' },
        { name: 'Sponserer' }
      ]
    };
  }
};
</script>

<style scoped>
.textfield {
  border-radius: 0px;
}
.checkbox {
  border-radius: 0px !important;
  font-weight: 200;
}
.btn {
  border-radius: 0px;
  text-transform: uppercase;
}
p {
  text-transform: uppercase !important;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
}
.listitem {
  padding: 0;
  font-weight: 300;
  min-height: 32px !important;
  color: rgb(169, 169, 169) !important;
}
.row {
  margin: 0;
}
.bottomtext {
  font-weight: 200;
  font-size: 15px;
  color: rgb(169, 169, 169) !important;
  letter-spacing: 0.4px;
}
</style>
