<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        xl="4"
        lg="6"
        md="6"
        v-for="hold in grupper"
        :key="hold.id"
      >
        <v-card
          elevation="1"
          class="card"
          height="250px"
          flat
          tile
          :color="!$vuetify.theme.dark ? '#F3F5F7' : '#191F28'"
        >
          <v-card-title class="my-6 font-weight-bold">{{
            hold.titel
          }}</v-card-title>
          <v-card-text style="font-size: 18px" class="mt-4">
            <v-row>
              <v-col>
                Periode {{ hold.periode_fra }} - {{ hold.periode_til }}
              </v-col>
              <v-col class="text-end"> Pris: {{ hold.pris }}</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="actions">
            <v-spacer></v-spacer>
            <v-btn large target="_blank" href="https://www.conventus.dk/dataudv/www/new_tilmelding.php?foreningsid=4056&gruppe=825140&skjul_nyt_medlem=0&skjul_allerede_medlem=0&sprog=da" dark tile small color="blue"> Info </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import conventusapi from '@/mixins/conventusapi';
export default {
  props: ['grupper'],
  data() {
    return {
      ledere: []
    };
  },
  mixins: [conventusapi]
};
</script>

<style scoped>
.actions {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
