<template>
  <div>
    grupper:
    {{ grupper }}
    <br />
    gruppe:
    {{ gruppe }}
  </div>
</template>

<script>
import conventusapi from '@/mixins/conventusapi';

export default {
  data() {
    return {
      grupper: [],
      gruppe: {}
    };
  },
  mixins: [conventusapi],
  async mounted() {
    this.grupper = await this.fetchGrupper();
    // grupper[0].id kan bruges nedenunder. Vi bruger bare mit U9 hold som eksempel: 796870
    this.gruppe = await this.fetchMedlemmer(796870);
  }
};
</script>
