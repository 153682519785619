<template>
  <div>
    <div class="background py-12">
      <v-container>
        <div class="SectionWrap">
          <div>
            <h1 class="display-5">Fodbold i Sole IF</h1>
            <div class="mt-4">
              Hos Sole IF tilbyder vi fodbold til alle, uanset hvilket niveau du
              er på. Om man er mest til ”hygge” bold eller gerne vil tage det
              lidt mere seriøst, så har vi noget til dig. I Sole IF vægter vi
              det sociale meget højt og vi har altid været kendt for at tage
              godt imod nye spillere.
              <br />
              <br />
              Vi har oftest 2 serie hold og du kan se holdene under fanen Hold.
              Du får altid 14 dages gratis træning, så du kan se, om det er
              noget for dig. Som fodboldspiller hos Sole IF, får du en masse nye
              kammerater og der er en fin kombination af unge og lidt ældre
              spillere. Der er som udgangspunkt træning 2 gange om ugen,
              medmindre der er helligdage osv. En fodbold sæson er delt op i
              forår og efterår. Derfor kan det godt variere lidt i, hvilke hold
              vi har fra år til år.
              <br />
              <br />
              Er du meget seriøs med fodbold, så tøv ikke med at spille i Sole
              IF. Vi går meget op i kvalitet til træning og arbejder altid hårdt
              for udvikle alle vores fodboldspillere på bedst mulige måde.
            </div>
          </div>

          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img
              class="Img"
              height="100%"
              cover
              src="images/fodbold.jpg"
            ></v-img>
          </div>
        </div>
      </v-container>
    </div>

    <!-- Ladies Section -->
    <div class="themeBackground py-12">
      <v-container>
        <div class="SectionWrap">
          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img
              class="Img"
              style="border-radius: 8px"
              height="350px"
              src="images/damer.jpg"
            ></v-img>
          </div>
          <div>
            <h2 class="display-5">Damer</h2>
            <div class="mt-4">
              Er du pige/dame og er vild med fodbold, så har vi også et hold til
              dig.
              <br />
              <br />
              Det er et 11 mands hold, hvor der er plads til alle. Her vægter vi
              også det sociale meget højt og der er ofte tid til en god snak
              efter træning eller kamp, kan du se hvilken række vi spiller i.
              <br />
              <br />
              Vi tilbyder altid 14 dages gratis træning, så du kan se om det er
              noget for dig.
              <br />
              <v-btn to="/holdoversigtjs" large class="mt-4 mr-2 primary">
                Tilmelding her
              </v-btn>
              <v-btn
                to="/holdoversigtjs"
                color="primary"
                outlined
                large
                class="mt-4"
              >
                Kontakt træneren
              </v-btn>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <!-- Junior section -->
    <div class="py-12 background">
      <v-container>
        <div class="SectionWrap">
          <div>
            <h2 class="display-5">Ungdom</h2>
            <div class="mt-4">
              I Sole IF har vi flere forskellige ungdomshold, som ofte starter
              omkring 4-årsalderen. Vi er meget afhængig af, hvor mange elever
              der er på skolen, så er du i tvivl om der er et hold til dit barn,
              så kan du se det under fanen Hold oversigt.
              <br />
              <br />
              Sole IF går rigtig meget op i at udvikle og lære børn at spille
              fodbold. Hos os er der plads til leg og læring, samtidig med et
              øvet niveau hvis dit barn ønsker dette. Vi har et klubsamarbejde
              med Vejle Boldklub, hvor dit barn kan komme til træning derinde
              også. Vejle Boldklub kigger hvert år på en bestemt årgang, så hvis
              der er et par talenter imellem, så bliver de ikke overset. Dette
              er selvfølgelig efter eget valg, så ønsker man kun at spille i
              Sole IF, er det også helt ok.
              <br />
              <br />
              Sole IF fik i 2022, startet det første samarbejde med Vejle
              Boldklub omkring pigefodbold. Det er vi rigtig stolte af og det
              betyder også, at der er rig mulighed for at udvikle dit barn,
              uanset om du har en dreng eller pige. Vi tilbyder altid 14 dages
              gratis træning, så du kan se om dette er noget for dit barn. Alle
              spillere er altid velkomne, uanset om man er helt ny på
              fodboldbanen eller øvet. For mere info kan de forskellige trænere
              ses under Hold eller trænere.
              <br />
              <v-btn to="/holdoversigtjs" large class="mt-4 mr-2 primary">
                Tilmelding her
              </v-btn>
              <v-btn
                to="/holdoversigtjs"
                color="primary"
                outlined
                large
                class="mt-4"
              >
                Kontakt træneren
              </v-btn>
            </div>
          </div>
          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img height="100%" src="images/junior.jpg"></v-img>
          </div>
        </div>
      </v-container>
    </div>

    <!-- Oldboys section -->
    <div class="themeBackground py-12">
      <v-container>
        <div class="SectionWrap">
          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img height="100%" src="images/oldboys.png"></v-img>
          </div>
          <div>
            <h2 class="display-5">Oldboys</h2>
            <div class="mt-4">
              Er du 30+ og gerne vil spille lidt fodbold, så har vi et hold til
              dig.
              <br />
              <br />
              Der er næsten aldrig træning på dette hold, men der bliver oftest
              spillet kamp hver onsdag. Der bliver spillet kampe fra april til
              september.
              <br />
              <br />
              Her har vi også et godt sammenhold og der er altid tid til en øl
              eller sodavand efter kamp. Det er et 11 mands hold og du er altid
              velkommen til at spille en kamp og se om det er noget for dig.
              Hvis dette er noget for dig, så kan du tilmelde dig via fanen Hold
              oversigt. Der er også en gruppe på Facebook, som du kan komme med
              i.
              <br />
              <br />
              Kontakt endelig træneren, hvis du har flere spørgsmål. Vil du
              kontakte træneren, kan du finde ham under fanen Trænere.
              <br />
              <v-btn to="/holdoversigtjs" large class="mt-4 mr-2 primary">
                Tilmelding her
              </v-btn>
              <v-btn
                to="/holdoversigtjs"
                color="primary"
                outlined
                large
                class="mt-4"
              >
                Kontakt træneren
              </v-btn>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <SponsorBanner />
  </div>
</template>

<script>
import SponsorBanner from '@/components/sider/homepage/SponsorBanner.vue';
export default {
  data() {
    return {
      grupper: []
    };
  },
  methods: {
    scrollTo(id) {
      const el = document.getElementById(id);
      const headerOffset = 65;
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      // el.scrollIntoView({ behavior: 'smooth', alignToTop: true });
    }
  },
  components: {
    SponsorBanner
  }
};
</script>

<style scoped>
.SectionWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
@media (max-width: 768px) {
  .SectionWrap {
    grid-template-columns: 1fr; /* Skifter til en enkelt kolonne for andre grids */
  }
  .Img {
    order: -1;
    margin-bottom: 10px;
  }
}
</style>
