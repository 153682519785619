var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{style:({
          'background-color': _vm.$vuetify.theme.dark
            ? 'rgba(0,0,0,0.97)'
            : 'rgba(255,255,255,0.99)'
        })},[_c('v-card-title',[_c('Avatar',{staticClass:"mr-4",attrs:{"fullname":_vm.$appstate.profile.navn,"radius":20,"size":40}}),_vm._v(" "+_vm._s(_vm.$appstate.profile?.navn)+" "),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$appstate.profile.email))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1),(_vm.$appstate.ledergrupper)?_c('div',_vm._l((_vm.$appstate.ledergrupper),function(gruppe){return _c('v-row',{key:gruppe},[_c('v-col',[_c('LederHold',{attrs:{"gruppeid":gruppe}})],1)],1)}),1):_vm._e(),_c('v-row',[_c('v-col',[(_vm.$appstate.conventusadmin)?_c('v-card',{style:({
              'background-color': _vm.$vuetify.theme.dark
                ? 'rgba(0,0,0,0.97)'
                : 'rgba(255,255,255,0.99)'
            })},[_c('v-card-title',[_vm._v("Conventus noter")]),_c('v-card-subtitle',[_vm._v("Dette er Sole's egen bunke af hjælpe noter omkring Conventus. "),_c('br'),_vm._v("De er kun synlige for dig, fordi du er med i gruppen conventus \"Hovedbestyrelsen\", \"Kampfordeler\", \"Kasserer\" eller \"Webmastere\".")]),_c('div',{staticClass:"pa-4"},[_vm._v(" Trænere og hjælpetrænere skal oprettes eller tilføjes ved at redigere holdet og tilføje her. "),_c('img',{staticClass:"elevation-2 pa-4 ma-4",attrs:{"src":"conventus/conventus-2022-11-05-071337.png"}})])],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }