<template>
  <v-row justify="center" no-gutters>
    <v-col
      class="mx-12 my-16"
      align-self="center"
      cols="4"
      lg="2"
      md="2"
      v-for="sponsor in sponsors"
      :key="sponsor.name"
    >
      <a :href="sponsor.link" target="_blank">
        <v-img contain :src="sponsor.img"> </v-img>
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      sponsors: [
        {
          name: 'Sportigan',
          img: 'https://sportigan.dk/wp-content/uploads/2021/09/cropped-Sportigan_logo_lokalsport.png',
          link: 'https://sportigan.dk/?fbclid=IwAR0lE6MXJ1sm2ho05fSDiiSiG9XwS3MiPERHOXEzovT_W5HiIeHUJaQE3Zo'
        },
        {
          name: 'KSM',
          img: ' https://www.ksm-kragelund.dk/wp-content/uploads/2022/03/ksm-as-logo.svg',
          link: 'https://www.ksm-kragelund.dk/'
        },
        {
          name: 'Unimerchants',
          img: ' https://www.unimerchants.com/wp-content/uploads/sites/2/2019/02/UM_Logo_2_cmyk_2016.png',
          link: 'https://www.unimerchants.com/'
        }
      ]
    };
  }
};
</script>
