<template>
  <div>
    <div class="background py-12">
      <v-container>
        <div class="SectionWrap">
          <div>
            <h1 class="display-5">Sole Esport</h1>
            <div class="mt-4">
              <h3>Om os</h3>
              Sole Esport er en Esports forening stiftet i 2018 som under
              afdeling af Sole if, med det mål for øje at skabe en forening,
              hvor børn og unge kan samles omkring deres fælles interesse for
              Esport i trygge faste rammer.
            </div>
            <div class="mt-4">
              <h3>Fællesskab</h3>
              Sole Esport ønsker blandt andet at sætte fokus på et sundt
              læringsmiljø for dem der elsker computerspil og Esport. Foreningen
              er i kraftig vækst og bygger på nogle solide værdier såsom
              respekt, ansvarlighed, fællesskab, motion, udvikling og fairplay.
            </div>
            <div class="mt-4">
              <h3>Udvikling</h3>
              Du vil blive undervist i kommunikation, strategi og
              spilfærdigheder, som er fundamentale for, at du kan blive bedre
              til dit spil. I Sole eSport er fællesskab og teamspirit afgørende,
              derfor vægter vi udvikling af de sociale færdigheder højt.
            </div>
          </div>

          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img height="100%" cover src="images/esporttheme.jpg"></v-img>
          </div>
        </div>
        <div class="mt-4">
          <h3>Motion</h3>
          I og med, at esport en inaktiv sport i sine basale forudsætninger, er
          det altså endnu vigtigt at øger fokus på den fysiske del, end ved
          andre almene sportsgrene. Nogle vil måske være uenige, da man, som
          udgangspunkt, bliver god til dét, som man øver sig på. Og, man skal
          vel øve sig på det, man skal konkurrere i. Altså spillet. Men, når det
          gælder om udholdenhed, og med udholdenhed kommer dygtighed, så er den
          fysiske komponent utrolig meget i spil, derfor er vores fokus at træne
          hele kroppen. Enhver person over 8 år kan blive medlem af foreningen.
          Umyndige medlemmer skal have forældrenes samtykke, da der kan spilles
          spil hvor PEGI (Pan-European Game Information) mærkningen er højere
          end barnets alder. Sole eSport har til huse på Øster snede skole, her
          har vi klublokalet med 15 stærke computer og 144 hz skærme. Som
          spiller i sole Esport skal man medbringe din egen mus, tastatur eller
          headset, ved prøvetræning eller lignede kan man også låne det hele hos
          os! Men vi anbefaler at du medbringer dit eget.
        </div>
      </v-container>
    </div>

    <!-- Signup Section -->
    <div class="themeBackground py-12">
      <v-container>
        <div>
          <div style="overflow: hidden; border-radius: 8px">
            <h3 style="font-size: 32px" class="mb-4 text-center">
              Esports hold
            </h3>
            <v-img
              class="mb-6"
              style="border-radius: 8px"
              height="350px"
              src="images/esport.jpg"
            ></v-img>
          </div>
          <div>
            <div class="mt-4">
              <v-row class="SignupSection">
                <v-col cols="12" lg="3" sm="6">
                  <h3>CS 2</h3>
                  <div>
                    CS:GO for begyndere og letøvede (NewbiezZ) - ideel for nye
                    spillere. Lær om spillets grundlæggende: Maps, våben,
                    granater, bevægelse, og kommunikation. Medbring egen mus,
                    tastatur, og headset. Træningstidspunkter: Torsdag kl. 17.00
                    – 19.00. Pris: 425kr.
                  </div>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <h3>CS2 Oldboys 30+</h3>
                  <div>
                    Fokus på spillets elementer som Maps, strategier, våben, og
                    kommunikation for 30+ spillere. Indeholder også motion og
                    koordineringsøvelser. Husk at medbringe egen udstyr.
                    Træningstidspunkter: Tirsdag kl. 17.00 – 19.00. Mål om 1-2
                    hold i DGI ligaen, spil hver søndag kl. 17/21. Pris: 425kr.
                  </div>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <h3>Passivt medlemskab</h3>
                  <div>
                    For dem, der ønsker at være en del af Sole Esport uden
                    ugentlig træning. Adgang til events som klubaftner, hygge
                    gaming og LAN-weekender. Ideelt til at forblive forbundet
                    med fællesskabet.
                    <br />
                    <br />
                    Pris: 250 kr. for 6 måneder.
                  </div>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <h3>Fortnite</h3>
                  <div>
                    Fortnite træning i bygning, skydning og editing. Fokus på
                    positiv kommunikation og samarbejde. God mulighed for at
                    skabe nye venskaber. Medbring egen mus, tastatur, og
                    headset.
                    <br />
                    Træningstidspunkter: Mandag <br />
                    kl. 17.00 – 19.00. Pris: 425kr.
                  </div>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col>
                  <v-btn to="/holdoversigtjs" large class="mt-4 mr-2 primary">
                    Tilmelding her
                  </v-btn>
                  <v-btn
                    to="/holdoversigtjs"
                    color="primary"
                    outlined
                    large
                    class="mt-4"
                  >
                    Kontakt træneren
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <!-- Junior section -->
    <div class="py-12 background">
      <v-container>
        <div class="SectionWrap">
          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img height="100%" src="images/esportteam.jpg"></v-img>
          </div>
          <div>
            <div class="mb-6">
              <h3>Hygge gaming</h3>
              <div class="mt-2">
                Hygge gaming Hygge Gaming går ud på at møde op og være social
                sammen med alle andre fra klubben, oftest er det ret spontant
                når der afholdes hygge gaming idet en instruktør, leder eller
                anden frivillige i klubben lige finde en ledig tid i kalenderen
                til at åben esport lokalet – dette vil blive meldt ud i
                facebookgrupperne som den frivillige lige finder tid- det vil
                altså typisk være med kort varsel. Men alle klubbens medlemmer
                vil være velkommen.
              </div>
            </div>
            <div class="mb-6">
              <h3>Sole Esport FACEBOOKGRUPPE</h3>
              <div class="mt-2">
                Kunne du godt tænke dig at gå til Esport, men har du aldrig
                prøvet det før? – Er du lige flyttet til byen? – Eller vil du
                gerne flytte fra en anden klub til Sole Esport? Så er der rig
                mulighed for at prøve det først. Vi tilbyder 2 gratis
                prøvetræninger inden du skal betale kontingent.
              </div>
            </div>
            <div class="mb-6">
              <h3>Prøvetræning.</h3>
              <div class="mt-2">
                Sole Esport - Medlemsgruppe er for alle medlemmer, ledere,
                trænere og forældre. Her deler vi alt den nødvendige
                information, ligesom det er muligt at komme i kontakt med
                klubbens styregruppe, trænere og andre medlemmer eller deres
                forældre. I din tilmeldings bekræftelse vil du modtage link til
                den relevante Facebook gruppe.
              </div>
            </div>
            <div class="mb-6">
              <h3>LAN-weekend</h3>
              <div class="mt-2">
                I Sole Esport afholder vi 2 gange om året en lan- weekend, en
                weekend hvor alle hold mødes på tværs og spiller og hygger sig
                sammen typisk fra fredag eftermiddag til søndag middag. Sådan en
                weekend indeholder meget mere en bare at game, der vil være
                mulighed for at dyrke sport i hallen, spille bord fodbold, hygge
                sig på sportspladsen. LAN-weekenden er drevet at frivillige
                kræfter lige fra leder, træner, instruktør, forældre og ildsjæle
                og derfor skal alle deltager også give en hånd med til det
                praktiske.
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <div class="py-12 themeBackground">
      <v-container>
        <div>
          <div>
            <div class="mb-6">
              <h3 style="font-size: 32px" class="text-center mb-8">Regler</h3>
              <div class="mt-2">
                <ul>
                  <li>Snak ordentligt til hinanden</li>
                  <li>Udvis respekt for Instruktøren</li>
                  <li>
                    Nul tolerance for rygning, snus, stoffer, energidrik og
                    alkohol eller på anden måde være påvirket
                  </li>
                  <li>Behandle udstyret ordentligt og med respekt</li>
                  <li>
                    Man er erstatningspligtig ved forsætlig skade på klubbens
                    udstyr eller lokaler
                  </li>
                  <li>
                    Efterlad altid lokalet pæn og ryddeligt, som det så ud da
                    man kom
                  </li>
                  <li>
                    Kun administratorer må installere og ændre på ting i
                    computerne
                  </li>
                  <li>Alle former for snyd er ikke tilladt</li>
                  <div class="mt-4">
                    <span style="font-style: italic"
                      >Der gælder nogle specielle regler for afviklingen af
                      hold, hvor deltagelsen aldersmæssigt er over 18 år.</span
                    >
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <SponsorBanner />
  </div>
</template>

<script>
import SponsorBanner from '@/components/sider/homepage/SponsorBanner.vue';
export default {
  data() {
    return {
      grupper: []
    };
  },
  methods: {
    scrollTo(id) {
      const el = document.getElementById(id);
      const headerOffset = 65;
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      // el.scrollIntoView({ behavior: 'smooth', alignToTop: true });
    }
  },
  components: {
    SponsorBanner
  }
};
</script>

<style scoped>
.SignupSection >>> h3 {
  font-size: 16px;
  margin-bottom: 12px;
}
.SignupSection >>> div {
  font-size: 14px;
}
.SectionWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
li {
  margin-top: 12px;
}
@media (max-width: 768px) {
  .SectionWrap {
    grid-template-columns: 1fr;
  }
  .Img {
    order: -1;
    margin-bottom: 10px;
  }
}
</style>
