<template>
  <div>
    <GroupInfoCard :gruppeid="89392"></GroupInfoCard>
    <GroupInfoCard :gruppeid="144374"></GroupInfoCard>
    <GroupInfoCard :gruppeid="692127"></GroupInfoCard>
    <CustomCard>
      <template v-slot:title>Pædofilpolitik i Sole IF</template>
      <p>
        Vi har alle et ansvar for at det skal være trygt at komme i Sole IF.
        Derfor har vi i klubben en holdning til pædofili og lavet nogle
        retningslinier for hvad man må og hvad man ikke må.
      </p>
      <p>
        For at sikre børn ikke bliver udsat for seksuelle overgreb, og at vore
        trænere/leder kan færdes i klubben uden at blive mistænkt for pædofili
        har vi opstillet følgende retningslinjer.
      </p>
      <p>
        Trænere/ledere bør undgå at være alene med 1 barn i omklædningsrummet.
      </p>
      <p>
        Mobiltelefoner skal være slukket/gemt væk i omklædningsrummet, således
        at der ikke kan tages billeder.
      </p>
      <p>
        Det er selvfølgelig tilladt at give spillerne en omfavnelse i
        forbindelse med en succesoplevelse, og give dem en trøstende omfavnelse.
        Det er ikke tilladt at give børnene smækkys, samt berøre børnene i
        skridtet og på brysterne.
      </p>
      <p>
        Der bliver indhentet straffeattest på alle trænere/ledere i
        Kriminalregistret, således at vi sikrer at ingen tidligere har været
        dømt for seksuelle omgang med børn.
      </p>
      <p>
        Alle som har en begrundet mistanke eller høre rygter skal kontakte en af
        nedennævnte personer.
      </p>
      <p>
        Hvad gør jeg hvis mistanken opstår? <br />
        Klubben skal i samarbejde med den person som nære mistanke vurdere om
        mistænkte skal anmeldes. Hvis vi vurdere at mistanken er helt ubegrundet
        skal klubben med det samme stoppe alt sladder og rygtedannelse.
      </p>
      <p>Kontaktpersoner ved begrundet mistanke:</p>
      <p>Bestyrelsesformanden i SOLE IF.</p>

      <p>Jan Darfelt, Danmarks Idrætsforbund, proff Tlf: 43262031</p>
    </CustomCard>
    <CustomCard>
      <template v-slot:title>Vedtægter Sole IF</template>
      <p>
        §1 Foreningens navn er Sole Idrætsforening (Sole IF). Foreningen har
        hjemsted i Hedensted Kommune og blev stiftet den 1. april 1941.
      </p>
      <p>
        §2 Foreningens formål er gennem idræt at fremme den enkeltes og
        fællesskabets sundhed. Møder og arrangementer kan forekomme i
        forbindelse med idrætten. Foreningen er upolitisk.
      </p>
      <p>
        §3 Foreningen er tilsluttet DIF, DGI og DBU. Kontingent fastsættes af
        bestyrelsen.
      </p>
      <p>
        §4 Både børn og voksne kan optages som medlemmer, såfremt de vedkender
        sig nærværende vedtægter. Bestyrelsen kan udelukke et medlem, når den
        finder grund dertil. Sagen kan, hvis medlemmet ønsker det, appelleres og
        vil da blive behandlet på førstkommende generalforsamling, hvortil
        medlemmet har adgang.
      </p>
      <p>
        §5 Foreningen ledes af en bestyrelse bestående af 7 bestyrelsesmedlemmer
        samt en kasserer. Kassereren vælges særskilt på generalforsamlingen og
        behøver ikke at være medlem af bestyrelsen. Bestyrelsesmedlemmer,
        kasserer og revisor vælges for 2 år ad gangen. 3 bestyrelsesmedlemmer
        afgår de lige år og 4 medlemmer, revisor, samt kassereren afgår de ulige
        år. Bestyrelsen konstituerer sig selv på det første bestyrelsesmøde
        efter generalforsamlingen, hvor minimum formand og næstformand vælges,
        bestyrelsen er yderligere bemyndiget til selv at vælge og nedsætte
        eventuelle udvalg. Udtræder kassereren i utide er bestyrelsen endvidere
        bemyndiget til at finde en afløser. Denne skal i givet fald godkendes på
        førstkommende generalforsamling. Bestyrelsesmedlemmer skal være fyldt 18
        år. Der er ikke et krav om at bestyrelsesmedlemmer skal være aktive
        medlemmer.
      </p>
      <p>
        §6 Foreningens bestyrelse og medlemmer hæfter ikke personligt for de af
        foreningen indgåede forpligtelser. Foreningen hæfter for disse med dens
        respektive formue. Foreningens medlemmer har ikke nogen økonomisk
        forpligtelse udover kontingent forpligtelsen. Foreningens medlemmer har
        ikke krav på nogen del af foreningens formue eller udbytte af nogen art.
      </p>
      <p>
        §7 Foreningens højeste myndighed er generalforsamlingen, der afholdes
        hvert år inden udgangen af november måned og som skal annonceres på Sole
        IFs sociale medier med mindst 8 dages varsel. Forslag der ønskes
        behandlet på en generalforsamling skal indsendes skriftligt til
        formanden senest 5 dage før generalforsamlingen. På generalforsamlingen
        afgøres sagerne ved almindeligt stemmeflertal (over halvdelen af de
        afgivne gyldige stemmer). Dog kræves til udelukkelse af et medlem og til
        ændring af disse vedtægter mindst 2/3 af de afgivne stemmer for
        forslaget. Skriftlig afstemning skal finde sted, når ønske herom
        fremsættes. Såfremt et medlem af bestyrelsen ikke modtager genvalg, kan
        bestyrelsen udpege en person. Stiller flere personer op til bestyrelsen,
        afgøres dette ved skriftlig afstemning til generalforsamling. Stemmeret
        har medlemmer der er fyldt 18 år samt personer, der er forældre eller
        værge for et medlem. Bestyrelsesmedlemmer i Sole IF, Sole fonden og
        sommerfestudvalget har også stemmeret. Hver person har KUN 1 stemme,
        såfremt man sidder i flere foreninger eller udvalg. Forældre eller
        værger har også kun 1 stemme, hvis de har flere børn som medlemmer. Der
        føres protokol over vedtagne beslutninger.
      </p>
      <p>§8 På den ordinære generalforsamling behandles følgende:</p>
      <ol>
        <li>Valg af dirigent</li>
        <li>Aflæggelse af beretning</li>
        <li>Forelæggelse af regnskabet for det foregående år</li>
        <li>Behandling af indkomne forslag</li>
        <li>
          Valg af:
          <ul>
            <li>Kasserer (ulige år, se dog §5)</li>
            <li>Bestyrelsesmedlemmer (3 lige år – 4 ulige år)</li>
            <li>Suppleanter (2 hvert år)</li>
            <li>Revisor (ulige år)</li>
          </ul>
        </li>
        <li>Eventuelt</li>
      </ol>
      <p>
        §9 Ekstraordinær generalforsamling skal afholdes, når flertallet af
        bestyrelsen finder det nødvendigt, eller når mindst ¼ af foreningens
        medlemmer stiller skriftligt krav herom med forslag til dagsordenen.
        Ekstraordinær generalforsamling skal afholdes senest 4 uger efter, at
        kravet herom er modtaget, og den skal indvarsles i lighed med ordinær
        generalforsamling.
      </p>
      <p>
        §10 Foreningens tegnes af mindst 5 medlemmer af bestyrelsen, hvoraf
        formanden skal være den ene. Ved optagelse af lån af den samlede
        bestyrelse.
      </p>
      <p>§11 Bestyrelsen fastsætter selv sin forretningsorden.</p>
      <p>
        §12 Vedtagelse i bestyrelsen kræver, at over halvdelen af bestyrelsen
        stemmer herfor.
      </p>
      <p>§13 Foreningens regnskab går fra 01-01 til 31-12.</p>
      <p>
        §14 Foreningen kan kun opløses, når 2/3 af deltagerne i 2 på hinanden
        følgende ekstraordinære generalforsamlinger stemmer herfor (indkaldelse
        se §9). Ophører Foreningen med at eksistere tilfalder dens formue
        idræts- og ungdomsarbejdet i Hedensted Kommune.
      </p>
      <p>
        Ovenstående vedtægter er vedtaget på ekstraordinær generalforsamling 2.
        oktober 2024.
      </p>
      <p>Formand: Dirigent:</p>
      <p>__________________ ___________________</p>
      <p>Simon Stockholm Michael Leerhøy</p>
    </CustomCard>
  </div>
</template>

<script>
import CustomCard from '@/components/blocks/CustomCard.vue';
import conventusapi from '@/mixins/conventusapi';
import GroupInfoCard from '@/components/blocks/GroupInfoCard.vue';
export default {
  components: {
    CustomCard,
    GroupInfoCard
  },
  data() {
    return {
      bestyrelse: null
    };
  },
  mixins: [conventusapi],
  methods: {
    ended() {}
  },
  async mounted() {
    // this.bestyrelse = (await this.fetchMedlemmer(89392)).medlemmer.medlem;
    // console.log(this.bestyrelse);
    // const esportGrupper = grupper.filter((gruppe) => {
    //   return gruppe.afdeling === '21775' && gruppe.offentlig === 'ja';
    // });
    // this.grupper = esportGrupper;
  }
};
</script>
