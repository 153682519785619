import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VideoBackground from 'vue-responsive-video-background-player';
import { observableState } from '@/components/applicationstate';

Vue.component('video-background', VideoBackground);

Vue.config.productionTip = false;
Vue.prototype.$appstate = observableState.state;

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
