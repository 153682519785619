<template>
  <div>
    <div class="background py-12">
      <v-container>
        <div class="SectionWrap">
          <div>
            <h1 class="display-5">Badminton i Sole IF</h1>
            <div class="mt-4">
              Er du interesseret i en sjov og aktiv aften med badminton?
              <br />
              <br />
              Hos vores Sole IF tilbyder vi dig muligheden for at booke en
              badmintonbane og nyde en spilleaften efter eget ønske.
              <br />
              <br />
              Vores faciliteter er ideelle for både afslappede spil og
              konkurrenceprægede matcher, og de står til rådighed for spillere
              på alle niveauer.
              <br />
              <v-btn to="/holdoversigtjs" large class="mt-4 mr-2 primary">
                Tilmelding her
              </v-btn>
            </div>
          </div>

          <div class="Img" style="overflow: hidden; border-radius: 8px">
            <v-img
              style="border-radius: 8px"
              height="450px"
              cover
              src="images/badminton.jpg"
            ></v-img>
          </div>
        </div>
      </v-container>
    </div>

    <SponsorBanner />
  </div>
</template>

<script>
import SponsorBanner from '@/components/sider/homepage/SponsorBanner.vue';
export default {
  data() {
    return {
      grupper: []
    };
  },
  methods: {
    scrollTo(id) {
      const el = document.getElementById(id);
      const headerOffset = 65;
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      // el.scrollIntoView({ behavior: 'smooth', alignToTop: true });
    }
  },
  components: {
    SponsorBanner
  }
};
</script>

<style scoped>
.SectionWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
@media (max-width: 768px) {
  .SectionWrap {
    grid-template-columns: 1fr; /* Skifter til en enkelt kolonne for andre grids */
  }
  .Img {
    order: -1;
    margin-bottom: 10px;
  }
}
</style>
