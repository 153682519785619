<template>
  <div v-if="grupper">
    <v-row v-for="hold in grupper" :key="hold.id" class="mx-1" justify="center">
      <v-col lg="8" md="10" sm="12" xs="12">
        <team-card :hold="hold" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import conventusapi from '@/mixins/conventusapi';
import TeamCard from '../../components/sider/teampage/TeamCard.vue';

export default {
  data() {
    return {
      grupper: [],
      gruppe: {}
    };
  },
  mixins: [conventusapi],
  async mounted() {
    this.grupper = await this.fetchGrupper();
  },
  components: {
    TeamCard
  }
};
</script>
