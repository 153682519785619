<template>
  <v-container>
    <v-card
    class="mb-2"
      :style="{
        'background-color': $vuetify.theme.dark
          ? 'rgba(0,0,0,0.97)'
          : 'rgba(255,255,255,0.99)'
      }"
    >
      <v-card-title>
        {{ title }}
      </v-card-title>
      <div>
        <slot />
      </div>
      <v-card-text>
        <v-data-table
          :items="medlemmer"
          :headers="headers"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// import conventusapi from '@/mixins/conventusapi';
import conventusapi from '@/mixins/conventusapi';

export default {
  props: {
    gruppeid: {
      type: Number,
      required: false
    }
  },
  mixins: [conventusapi],
  data() {
    return {
      medlemmer: [],
      gruppe: null,
      title: '',
      visible: true,
      headers: [
        {
          text: 'Navn',
          align: 'left',
          sortable: false,
          value: 'navn'
        },
        { text: 'Mobil', value: 'mobil' },
        { text: 'Email', value: 'email' },
        { text: 'Adresse', value: 'adresse1' },
        { text: 'By', value: 'postnr_by' }
      ]
    };
  },
  async mounted() {
    const response = await this.fetchMedlemmer(this.gruppeid);
    this.medlemmer = response.medlemmer.medlem;
    this.gruppe = (await this.fetchGruppe(this.gruppeid)).gruppe;
    this.title = this.gruppe.titel;
    // return
    // if (this.gruppe.gruppe.type === 'udvalg') {
    //   this.visible = false;
    // }
  },
  computed: {},
  methods: {
    ended() {}
  }
};
</script>
