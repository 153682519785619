<template>
  <div>
    <v-card :to="sport.route">
      <v-img class="img" height="350px" :src="sport.img">
        <v-row>
          <v-col class="text-center">
            <v-icon size="75" color="white">{{ sport.icon }}</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center white--text font-weight-nold">
            <span class="display-2">{{ sport.name }}</span>
          </v-col>
        </v-row>
      </v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['sport']
};
</script>

<style scoped>
.img {
  align-items: center;
  transition: transform 0.5s; /* Animation */
}
.img:hover {
  transform: scale(1.05);
  cursor: pointer;
  z-index: 1;
}
</style>
