<template>
  <v-app>
    <NavBar :transparentmenu="$route.path === '/' ? true : false" />
    <v-main
      :style="$route.path === '/' ? 'margin-top: 0px' : 'margin-top: 70px'"
    >
      <router-view />
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script>
import NavBar from '@/components/layout/NavBar.vue';
import PageFooter from './components/layout/PageFooter.vue';
export default {
  components: {
    NavBar,
    PageFooter
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500&display=swap');
#app {
  background-color: rgba(1, 1, 1, 0.4);
  background-image: url('./assets/solebg.svg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
* {
  text-transform: none !important;
  font-family: Montserrat, sans-serif;
}
.v-toolbar__content {
  padding: 0px !important;
}
.container {
  max-width: 1200px !important;
}
</style>
