<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#ac1927" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" :to="item.to">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="openshop">
          <v-list-item-title>Shop</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <div class="d-flex justify-center">
            <v-icon class="ml-4 mt-0" color="yellow"
              >mdi-white-balance-sunny</v-icon
            >
            <v-switch
              style="margin-top: 0px; padding-top: 4px"
              class="ml-2"
              color="red darken-3"
              @click="toggleDarkMode"
              :dark="transparentmenu && !scrolled ? true : $vuetify.theme.dark"
              v-model="theme"
              hide-details
            >
            </v-switch>
            <v-icon color="blue">mdi-weather-night</v-icon>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import themehandler from '@/mixins/themehandler';

export default {
  data() {
    return {
      logindialogue: false,

      items: [
        { name: 'Forside', to: '/' },
        { name: 'Hold oversigt', to: '/holdoversigtjs' },
        { name: 'Klubinfo', to: '/klubinfo' },
        { name: 'Mit Sole', to: '/profil' }

      ]
    };
  },
  props: {
    transparentmenu: {
      type: Boolean,
      required: false,
      default: false
    },
    scrolled: {
      type: Boolean,
      required: false
    }
  },
  mixins: [themehandler],
  methods: {
    openshop() {
      window.open('https://sportiganhedensted.dk/sportigan-hedensted/?fbclid=IwY2xjawFets1leHRuA2FlbQIxMQABHXOkSpWe6JrlJbUDGEHvxc2reE91vrENpRjeUePX1_IkuTc7kwVldvTvfA_aem_uZhCftvGhD1uyCZQcK40mA#!/Sole-IF/c/150924263');
    }
  }
};
</script>
