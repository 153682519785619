<template>
  <v-container style="min-height: 100vh" class="fill-height">
    <v-row style="height: 100%" class="fill-height" justify="space-around">
      <v-col align-self="center" cols="12" md="8" lg="6">
        <v-card
          style="overflow: hidden"
          :style="{
            'background-color': $vuetify.theme.dark
              ? 'rgba(0,0,0,0.97)'
              : 'rgba(255,255,255,0.99)'
          }"
        >
          <v-form @submit.prevent="login">
            <v-card-title class="grey darken-4 white--text"
              >Sole Login (Conventus)</v-card-title
            >
            <v-card-text class="my-12">
              <v-text-field
                v-model="mobile"
                prepend-icon="mdi-account"
                name="mobile"
                label="Mobil"
                type="text"
              >
              </v-text-field>
              <v-text-field
                id="password"
                v-model="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-alert v-if="error != ''" dismissible type="warning">
                {{ error }}
              </v-alert>
              <a
                class="ml-4"
                target="_blank"
                href="https://www.conventus.dk/medlemslogin/before_login/glemt_password.php?forening=4056"
                >Glemt password</a
              >
              <v-spacer />
              <v-progress-circular v-if="loading" color="primary" indeterminate>
              </v-progress-circular>
              <v-btn :disabled="loading" text type="submit">Login</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog max-width="600" v-model="multivisible">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark
            >Vælg hvilken profil du vil bruge:</v-toolbar
          >
          <v-card-text>
            <v-list>
              <v-list-item-group v-model="selectlistprofile" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon
                    ><v-icon>mdi-account</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.navn }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Annuller</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import conventusapi from '@/mixins/conventusapi';
import { observableState } from '@/components/applicationstate';

export default {
  mixins: [conventusapi],
  data() {
    return {
      mobile: '',
      password: '',
      loading: false,
      multimedlemmer: [],
      multivisible: false,
      error: ''
    };
  },
  computed: {
    items() {
      return observableState.state?.multiprofiles;
    },
    selectlistprofile: {
      get: function() {
        return 0;
      },
      set: function(newValue) {
        this.profile = this.items[newValue];
      }
    },
    profile: {
      get: function() {
        return observableState.state.profile;
      },
      set: function(newValue) {
        observableState.state.profile = newValue;
        this.multivisible = false;
        this.LoadMoreAndRedirect();
      }
    }
  },
  methods: {
    async LoadMoreAndRedirect() {
      observableState.state.medlem = await this.fetchMedlem(
        observableState.state.profile.id
      );
      const ledersektion =
        observableState.state.medlem.relationer?.leder?.gruppe;
      if (Array.isArray(ledersektion)) {
        // ledergrupper er et array med flere id
        observableState.state.ledergrupper = ledersektion;
      } else {
        // ledergrupper er en string med et id
        observableState.state.ledergrupper = [ledersektion];
      }
      const medlemsektion =
        observableState.state.medlem.relationer?.medlem?.gruppe;
      if (Array.isArray(medlemsektion)) {
        // medlemgrupper er et array med flere id
        observableState.state.medlemgrupper = medlemsektion;
      } else {
        // medlemgrupper er en string med et id
        observableState.state.medlemgrupper = [medlemsektion];
      }

      observableState.state.conventusadmin =
        observableState.state.medlemgrupper.includes('89392') ||
        observableState.state.medlemgrupper.includes('158485') ||
        observableState.state.medlemgrupper.includes('565769') ||
        observableState.state.medlemgrupper.includes('149618');

      this.$router.push(observableState.state.target).catch(() => {
        this.$router.push('/profil');
      });
    },
    async login() {
      const response = await this.loginmedmobil(this.mobile, this.password);
      if (response.result === 'multiple_profiles') {
        observableState.state.multiprofiles = response.medlemmer;
        this.multivisible = true;
      }
      if (response.result === 'wrong_informations') {
        // error
        this.error = 'Mobil eller adgangskode er forkert.';
      }
      if (response.result === 'invalid_username') {
        // error
        this.error = 'Forkert mobil nummer';
      }
      if (response.result === 'success') {
        observableState.state.multiprofiles = [response.profil];
        this.profile = response.profil;
      }
    }
  }
};
</script>
