<template>
  <div>
    <div class="Hero">
      <template>
        <div>
          <div class="Hero">
            <div v-show="currentVideoObject" :key="currentVideoObject.name">
              <video-background
                class="HeroVideo"
                @ended="ended"
                :loop="false"
                :autoplay="true"
                :src="currentVideoObject.src"
                :ref="currentVideoObject.name"
                style="height: 70vh"
                overlay="rgba(0,0,0,0.4)"
              >
                <v-container class="white--text fill-height">
                  <v-row>
                    <v-col cols="12" sm="8">
                      <h1 class="font-weight-bold text-uppercase">
                        {{ currentVideoObject.title }}
                      </h1>
                      <h2 class="display-3 font-weight-bold text-uppercase">
                        {{ currentVideoObject.subtitle }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
              </video-background>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div
      class="py-12"
      :style="{
        'background-color': $vuetify.theme.dark
          ? '#1E1E1E'
          : 'rgba(255,255,255,0.99)'
      }"
    >
      <h2 class="mb-12 text-center">
        Hos Sole IF dyrker vi forskellige former for sport
      </h2>
      <v-container>
        <v-row>
          <v-col md="4" sm="6" v-for="sport in sports" :key="sport.name">
            <SportsCard :sport="sport" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <SponsorBanner />
  </div>
</template>

<script>
import SponsorBanner from '../../components/sider/homepage/SponsorBanner.vue';
import SportsCard from '../../components/sider/homepage/SportsCard.vue';
import EsportImg from '../../../public/images/esport.jpg';
import FootballImg from '../../../public/images/fodbold.jpg';

export default {
  data() {
    return {
      alertsommerfest: false,
      alertfodbold: false,
      videos: [
        {
          name: 'video1',
          src: 'video.mp4',
          title: 'Sommerfest',
          subtitle: 'fest og farver i juni'
        },
        {
          name: 'video2',
          src: 'serie3_2.mp4',
          title: 'Fodbold',
          subtitle: ' Senior afdeling med niveau'
        },
        {
          name: 'video3',
          src: 'v3.mp4',
          title: 'Fodbold',
          subtitle: 'Trivsel for de helt små'
        }
      ],
      currentVideo: 'video1',
      sports: [
        {
          name: 'Fodbold',
          icon: 'mdi-soccer',
          img: FootballImg,
          route: '/fodbold'
        },
        {
          name: 'Esport',
          icon: 'mdi-headset',
          img: EsportImg,
          route: '/esport'
        },
        {
          name: 'Badminton',
          icon: 'mdi-badminton',
          img: 'https://btbk.dk/sites/default/files/styles/forside_slides/public/slideshow/forside/21728791_1924825331118926_3616331124722064489_o.jpg',
          route: '/badminton'
        }
      ]
    };
  },
  computed: {
    currentVideoObject() {
      return this.videos.find((video) => video.name === this.currentVideo);
    }
  },
  methods: {
    ended() {
      let nextIndex =
        this.videos.findIndex((v) => v.name === this.currentVideo) + 1;
      if (nextIndex >= this.videos.length) {
        nextIndex = 0;
      }
      this.currentVideo = this.videos[nextIndex].name;
    }
  },

  components: {
    SportsCard,
    SponsorBanner
  }
};
</script>

<style scoped>
.HeroText {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
