<template>
  <div class="display-2 text-center">
    <costum-card>
      <v-row>
        <v-col> Siden du prøver at tilgå findes ikke. </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" tile outlined x-large to="/"> Forside </v-btn>
        </v-col>
      </v-row>
    </costum-card>
  </div>
</template>

<script>
import CostumCard from '../../components/blocks/CustomCard.vue';
export default {
  components: {
    CostumCard
  }
};
</script>
