import axios from 'axios';

export default {
  methods: {
    async loginmedmobil(mobilnr, password) {
      const formData = new FormData();
      formData.append('foreningsid', process.env.VUE_APP_CONVENTUSAPIFORENING);
      formData.append('log_ind_med', 'mobil');
      formData.append('version', '2');
      formData.append('mobil', mobilnr);
      formData.append('mobil_land_alpha2', 'DK');
      formData.append('adgangskode', password);
      // se mere på: https://www.conventus.dk/vejledninger/#dokument/62165
      const response = await axios.post('/api/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // response.data.result kan være en streng. f.eks "invalid_username"
      return response.data;
      // Formatet er JSON, hvor rod-objektet har feltet "result", som kan have følgende værdier:

      // result
      // invalid_username - Brugernavnet er hverken mobil eller e-mail
      // wrong_informations - Forkert brugernavn eller adgangskode
      // success - Medlemmets oplysninger er i feltet "profil"
      // multiple_profiles - Der er flere medlemmer med det brugernavn. Bemærk, dette er også et succesfuldt log ind.

      // const parser = new DOMParser();
      // const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      // const json = this.parseXmlNode(xmlDoc);
      // const grupper = json.conventus.grupper.gruppe;
      // return grupper;
    },
    async fetchGrupper() {
      const response = await axios.get('/api/grupper', {
        params: {
          key: process.env.VUE_APP_CONVENTUSAPIKEY,
          forening: process.env.VUE_APP_CONVENTUSAPIFORENING
        }
      });
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      const json = this.parseXmlNode(xmlDoc);
      const grupper = json.conventus.grupper.gruppe;
      return grupper;
    },
    async fetchTidOgSted(gruppeid) {
      const response = await axios.get('/api/tid_sted', {
        params: {
          key: process.env.VUE_APP_CONVENTUSAPIKEY,
          forening: process.env.VUE_APP_CONVENTUSAPIFORENING,
          grupper: gruppeid
        }
      });
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      const json = this.parseXmlNode(xmlDoc);
      const grupper = json.conventus;
      return grupper;
    },
    async fetchGruppe(gruppeid) {
      const response = await axios.get('/api/gruppe', {
        params: {
          key: process.env.VUE_APP_CONVENTUSAPIKEY,
          forening: process.env.VUE_APP_CONVENTUSAPIFORENING,
          id: gruppeid
        }
      });
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      const json = this.parseXmlNode(xmlDoc);
      const gruppe = json.conventus;
      return gruppe;
    },
    async fetchMedlem(medlemsid) {
      const response = await axios.get('/api/medlem', {
        params: {
          key: process.env.VUE_APP_CONVENTUSAPIKEY,
          forening: process.env.VUE_APP_CONVENTUSAPIFORENING,
          id: medlemsid,
          relationer: true,
          relationer_aktiv: true,
          ekstra_felter: true
        }
      });
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      const json = this.parseXmlNode(xmlDoc);
      const medlem = json.conventus.medlem;
      return medlem;
    },
    async fetchMedlemmer(gruppeid) {
      const response = await axios.get('/api/medlemmer', {
        params: {
          key: process.env.VUE_APP_CONVENTUSAPIKEY,
          forening: process.env.VUE_APP_CONVENTUSAPIFORENING,
          grupper: gruppeid
        }
      });
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      const json = this.parseXmlNode(xmlDoc);
      const medlemmer = json.conventus;
      return medlemmer;
    },
    parseXmlNode(node) {
      const childNodes = node.childNodes;
      if (childNodes.length === 0) {
        return node.nodeValue;
      } else if (
        childNodes.length === 1 &&
        childNodes[0].nodeType === Node.TEXT_NODE
      ) {
        return childNodes[0].nodeValue;
      } else {
        const obj = {};
        childNodes.forEach((childNode) => {
          const childName = childNode.nodeName;
          const childValue = obj[childName];
          if (childValue !== undefined) {
            if (Array.isArray(childValue)) {
              childValue.push(this.parseXmlNode(childNode));
            } else {
              obj[childName] = [childValue, this.parseXmlNode(childNode)];
            }
          } else {
            obj[childName] = this.parseXmlNode(childNode);
          }
        });
        return obj;
      }
    }
  }
};
